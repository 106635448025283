/* eslint-disable prettier/prettier */
// src/pages/AdminReviewPanel.js
import React from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Card,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import AuthenticatedContent from "../components/ui/AuthenticatedContent";
import GenReviewsHowUX from "../components/GenReviewsHowUX";

const AdminReviewPanel = () => {
  useDocumentTitle("Generate Reviews");

  return (
    <PageLayout>
      <AuthenticatedContent>
        <div className="content-layout">
          <h1 id="page-title" className="content__title">
            Generate Reviews
          </h1>
          <div className="content__body">
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <GenReviewsHowUX />
                  </Grid>
                  <Grid item xs={6}>
                    <Card
                      style={{ backgroundColor: "#bae8e8", padding: "15px" }}
                    >
                      <Typography>
                        Welcome to the review generation panel. You have two
                        options to get more reviews:
                        <ol>
                          <li>Share the QR code via print or email.</li>
                          <li>Upload your customer list in CSV format.</li>
                        </ol>
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={2}>
                    <Box>
                      <Paper elevation={3} style={{ textAlign: "center" }}>
                        <Typography variant="h6">
                          Import Data by uploading CSV
                        </Typography>
                        <Button variant="contained" color="primary">
                          Upload
                        </Button>
                      </Paper>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Paper elevation={3} style={{ textAlign: "center" }}>
                        <Typography variant="h6">
                          QR Code for Customer Reviews
                        </Typography>
                        <img
                          src="/qr.png"
                          alt="QR Code"
                          style={{ width: "50%" }}
                        />
                      </Paper>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Card
                      style={{ backgroundColor: "#bae8e8", padding: "15px" }}
                    >
                      <Typography>Customize your message:</Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={5}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h6">
                          Negative Review Text (1, 2 or 3 star ratings)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                          defaultValue="Default Negative Review Text"
                          variant="outlined"
                          fullWidth
                        />
                        <Button variant="contained" color="primary">
                          Save
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={4}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography variant="h6">
                          Positive Review Text (4 or 5 star ratings)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                          defaultValue="Default Positive Review Text"
                          variant="outlined"
                          fullWidth
                        />
                        <Button variant="contained" color="primary">
                          Save
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>

                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Total Customers</TableCell>
                            <TableCell>Customers Contacted</TableCell>
                            <TableCell>1 Star Reviews</TableCell>
                            <TableCell>2 Star Reviews</TableCell>
                            <TableCell>3 Star Reviews</TableCell>
                            <TableCell>4 Star Reviews</TableCell>
                            <TableCell>5 Star Reviews</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>100</TableCell>
                            <TableCell>80</TableCell>
                            <TableCell>5</TableCell>
                            <TableCell>2</TableCell>
                            <TableCell>3</TableCell>
                            <TableCell>30</TableCell>
                            <TableCell>40</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </AuthenticatedContent>
    </PageLayout>
  );
};

export default AdminReviewPanel;
