/* eslint-disable prettier/prettier */
// src/pages/insights.js
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";

const Insights = () => {
  const { isAuthenticated } = useAuth0();
  useDocumentTitle("Settings"); // Updated document title to 'Settings'

  if (!isAuthenticated) {
    return null;
  }

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Insights Page {/* Updated page title */}
        </h1>
        <div className="content__body">
          <p id="page-description"></p>
        </div>
      </div>
    </PageLayout>
  );
};

export default Insights; // Updated component name
