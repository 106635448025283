/* eslint-disable prettier/prettier */// src/pages/ProtectedPage.js
import React, { useEffect, useState } from "react";
import { Typography, Grid, Paper } from "@mui/material";
import { PageLayout } from "../components/page-layout";
import { fetchRatings } from "../services/ratings.service";
import RatingsBarChart from "../components/ui/RatingsBarChart";

const ProtectedPage = () => {
  const [ratingsData, setRatingsData] = useState({
    ratingCount: [],
    number30: [],
  });

  useEffect(() => {
    let isMounted = true;

    const fetchAndSetRatings = async () => {
      const data = await fetchRatings("bella_vista_7377088016");

      if (isMounted && data) {
        setRatingsData(data);
      }
    };

    fetchAndSetRatings();

    return () => {
      isMounted = false;
    };
  }, []);

  const fiveStarReviews =
    ratingsData.number30.find((item) => item.rating === "5")?.num_reviews ||
    "0";
  const oneStarReviews =
    ratingsData.number30.find((item) => item.rating === "1")?.num_reviews ||
    "0";
  const totalReviews = ratingsData.number30.reduce(
    (total, item) => total + parseInt(item.num_reviews),
    0
  );

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Protected Page
        </h1>
        <div className="content__body">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className="last30">
                <Typography variant="h6" className="last30title">
                  Last 30 days
                </Typography>
                <Typography variant="body1" className="last30reviews">
                  Reviews: {totalReviews}
                </Typography>
                <Typography variant="body1" className="last30_5star">
                  5 star reviews: {fiveStarReviews}
                </Typography>
                <Typography variant="body1" className="last30_1star">
                  1 star reviews: {oneStarReviews}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <RatingsBarChart ratings={ratingsData.ratingCount} />
            </Grid>
          </Grid>
        </div>
      </div>
    </PageLayout>
  );
};

export default ProtectedPage;
