/* eslint-disable prettier/prettier */
// src/components/ui/filters/DateRangeFilter.js
import React from "react";

const DateRangeFilter = ({ dateRange, onSelectDateRange }) => {
  return (
    <div className="date-range-filter">
      <label>Last: </label>
      <select
        value={dateRange}
        onChange={(e) => {
          onSelectDateRange(e.target.value);
        }}
      >
        <option value="All">All (Months)</option>
        <option value="last_month">Last month</option>
        <option value="last_3_months">Last 3 months</option>
        <option value="last_6_months">Last 6 months</option>
        <option value="last_12_months">Last 12 months</option>
      </select>
    </div>
  );
};

export default DateRangeFilter;
