/* eslint-disable prettier/prettier */
// src\components\ChatComponents\MessageBubble.js
import React, { useState, useEffect } from "react";
import { updateNotes } from "../../services/notes.service";

const MessageBubble = ({
  reviewText,
  isFlagged,
  handleFlagToggle,
  reviewNotes,
  reviewId,
}) => {
  const [showNotesBox, setShowNotesBox] = useState(false);
  const [notes, setNotes] = useState(reviewNotes || "");

  useEffect(() => {
    if (reviewNotes) {
      setShowNotesBox(true);
    }
  }, [reviewNotes]);

  const handleSaveOrUpdateNotes = async () => {
    const result = await updateNotes(reviewId, notes);
    if (result) {
      window.location.reload();
    }
  };

  return (
    <div className="message-bubble">
      <p className="reviewText">{reviewText}</p>
      {isFlagged && <p>🚩 Flagged</p>}
      <div className="action-buttons">
        <button onClick={handleFlagToggle}>
          {isFlagged ? "Unflag Review" : "Flag Review"}
        </button>
        <button onClick={() => setShowNotesBox(!showNotesBox)}>
          {showNotesBox ? "Hide Notes" : "Add Notes"}{" "}
          {/* Updated label based on the state */}
        </button>
      </div>
      {showNotesBox && (
        <div className="notes-box">
          <textarea value={notes} onChange={(e) => setNotes(e.target.value)} />
          <button onClick={handleSaveOrUpdateNotes}>
            {notes ? "UPDATE NOTES" : "SAVE NOTES"}
          </button>
        </div>
      )}
    </div>
  );
};

export default MessageBubble;
