/* eslint-disable prettier/prettier */
// src/services/review.service.js

import fetch from "node-fetch";

// Helper function to transform the star rating from string to number
const transformStarRating = (starRating) => {
  const ratings = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
  };
  return ratings[starRating] || 0; // Default to 0 if the rating is not recognized
};

export const fetchReviews = async (businessId) => {
  try {
    const res = await fetch(`/api/fetch-reviews?businessId=${businessId}`);
    const response = await res.json();

    // Check if the response contains the data array
    if (response && Array.isArray(response.data)) {
      // Transform the data to match the expected shape
      const transformedData = response.data.map((review) => ({
        review_id: review.reviewid,
        review_text: review.comment,
        rating: transformStarRating(review.starrating),
        review_date: review.updatetime || review.createtime,
        reviewer_name: review.reviewerdisplayname,
        profile_photo_url: review.reviewerphotourl,
        review_response: review.replycomment,
        // Include the new columns in the transformation
        flagged: review.flagged,
        approved_response: review.approved_response,
        proposed_response: review.proposed_response,
      }));

      // Log the first element of the transformed array for troubleshooting
      console.log(
        "First item in the transformed reviews array:",
        transformedData[0]
      );

      return transformedData; // Ensure we are returning the transformed array
    } else {
      console.log("Response data is not in expected format:", response);
      return []; // Return an empty array if data is not structured as expected
    }
  } catch (error) {
    console.error("Error fetching reviews:", error);
    return []; // Return an empty array in case of error
  }
};
