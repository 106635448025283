/* eslint-disable prettier/prettier */
import React from "react";
import { NavLink } from "react-router-dom";

export const MobileNavBarBrand = ({ handleClick }) => {
  return (
    <div onClick={handleClick} className="mobile-nav-bar__brand">
      <NavLink to="/">
        <img
          className="mobile-nav-bar__logo"
          src="https://res.cloudinary.com/sm-gcs/image/upload/v1689156423/RepBot/RepBot_Logo_mc180x.gif"
          alt="RepBot.ai Logo"
          width="50%"
          height="50%"
        />
      </NavLink>
    </div>
  );
};
