/* eslint-disable prettier/prettier */
// src/components/ProposedResponseBubble.js
import React from "react";

const ProposedResponseBubble = ({ proposedResponse }) => (
  <div className="proposed-response-bubble">
    <p>
      <strong>⭐AI Generated Response:</strong>
    </p>
    <p> {proposedResponse}</p>
  </div>
);

export default ProposedResponseBubble;
