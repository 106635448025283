/* eslint-disable prettier/prettier */
//src\components\navigation\desktop\nav-bar-brand.js
import React from "react";
import { NavLink } from "react-router-dom";

export const NavBarBrand = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/">
        <img
          className="nav-bar__logo"
          src="https://res.cloudinary.com/sm-gcs/image/upload/v1689156423/RepBot/RepBot_Logo_mc180x.gif"
          alt="RepBot.ai Logo"
          width="50%"
          height="50%"
        />
      </NavLink>
    </div>
  );
};
