/* eslint-disable prettier/prettier */
// src/services/generateDescription.js
import fetch from "node-fetch";

export const generateDescription = async (name, city, category) => {
  try {
    const apiUrl = `https://generate-description.repbot.workers.dev/?name=${name}&city=${encodeURIComponent(
      city
    )}&category=${encodeURIComponent(category)}`;

    const res = await fetch(apiUrl, {
      method: "GET", // Using GET method to align with your existing Web Worker
    });

    const data = await res.json();
    console.log("Fetched Data:", data); // New Log

    return data;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};
