/* eslint-disable prettier/prettier */
// src/components/LocationListRetrieval.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import useGMBIntegration from "../hooks/useGMBIntegration";
import LocationSelectionForm from "./LocationSelectionForm";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const LocationListRetrieval = () => {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [authData, setAuthData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [savingMessage, setSavingMessage] = useState(""); // New state for saving message
  const { user } = useAuth0();
  const userEmail = user?.email || "";
  const navigate = useNavigate();

  const code = useGMBIntegration().code;

  useEffect(() => {
    if (code) {
      setLoading(true);
      (async () => {
        try {
          const response = await axios.post("/api/save-token", { code });
          const { locations: fetchedLocations, ...restData } = response.data;

          setLocations(fetchedLocations || []);
          setSelectedLocation(fetchedLocations?.[0]);
          setAuthData(restData);
        } catch (error) {
          console.error("Error fetching data from api/save-token.js:", error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [code]);

  const handleChange = (location) => {
    setSelectedLocation(location);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (authData && selectedLocation) {
      const dataToSend = {
        gmbLocationName: selectedLocation.title,
        gmbLocationId: selectedLocation.name,
        userEmail: userEmail,
        ...authData,
      };

      axios
        .post(
          "https://api.retool.com/v1/workflows/7468de76-f66c-464e-8db3-203f79336d7f/startTrigger?workflowApiKey=retool_wk_2877aae6eeb9422f98867ca915019167",
          dataToSend
        )
        .then((response) => {
          console.log("API Response:", response.data);
        })
        .catch((error) => {
          console.error("Error sending data to API:", error);
        });

      setSavingMessage("Saving business to profile"); // Set message
      setTimeout(() => {
        setSavingMessage(""); // Clear message
        navigate("/settings"); // Redirect after 3 seconds
      }, 3000);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <p>Select an account to integrate:</p>
      {savingMessage && <h2>{savingMessage}</h2>} {/* Display saving message */}
      {code && locations && locations.length > 0 ? (
        <LocationSelectionForm
          locations={locations}
          selectedLocation={selectedLocation}
          onChange={handleChange}
          onSubmit={handleSubmit}
        />
      ) : (
        <p>No locations found or code is missing.</p>
      )}
    </div>
  );
};

export default LocationListRetrieval;
