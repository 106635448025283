/* eslint-disable prettier/prettier */ // src/components/ui/ReviewFilter.js
import React from "react";
import PropTypes from "prop-types";
import RatingFilter from "./filters/RatingFilter";
import DateSortFilter from "./filters/DateSortFilter";
import DateRangeFilter from "./filters/DateRangeFilter";
import ResponseFilter from "./filters/ResponseFilter";
import FlagFilter from "./filters/FlagFilter";
import "../../styles/components/review-filter.css";

const ReviewFilter = ({
  selectedRating,
  onSelectRating,
  sortByDate,
  onSortByDate,
  dateRange,
  onSelectDateRange,
  responded,
  onSelectResponded,
  flagged,
  onSelectFlagged,
}) => {
  return (
    <div className="review-filter">
      <div className="filter-section-wrapper">
        <h4 className="filter-header">Filter</h4>
        <div className="filter-section">
          <RatingFilter
            selectedRating={selectedRating}
            onSelectRating={onSelectRating}
          />
          <ResponseFilter
            responded={responded}
            onSelectResponded={onSelectResponded}
          />
          <FlagFilter flagged={flagged} onSelectFlagged={onSelectFlagged} />
        </div>
      </div>

      <div className="sort-section-wrapper">
        <h4 className="sort-header">Sort</h4>
        <div className="sort-section">
          <DateSortFilter sortByDate={sortByDate} onSortByDate={onSortByDate} />
          <DateRangeFilter
            dateRange={dateRange}
            onSelectDateRange={onSelectDateRange}
          />
        </div>
      </div>
    </div>
  );
};

ReviewFilter.propTypes = {
  // Existing propTypes
  selectedRating: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onSelectRating: PropTypes.func.isRequired,
  sortByDate: PropTypes.string.isRequired,
  onSortByDate: PropTypes.func.isRequired,
  dateRange: PropTypes.string.isRequired,
  onSelectDateRange: PropTypes.func.isRequired,
  responded: PropTypes.string.isRequired,
  onSelectResponded: PropTypes.func.isRequired,
  flagged: PropTypes.string.isRequired,
  onSelectFlagged: PropTypes.func.isRequired,
};

export default ReviewFilter;
