/* eslint-disable prettier/prettier */
// src/pages/reviews/ReviewsLayout.js
import React from "react";
import PropTypes from "prop-types";
import "../../styles/components/reviews.css";

const ReviewsLayout = ({ children }) => {
  return (
    <div className="content-layout reviews">
      <h1 id="page-title" className="content__title">
        Reviews
      </h1>
      {children}
    </div>
  );
};

ReviewsLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReviewsLayout;
