/* eslint-disable prettier/prettier */
// src/pages/overview-page.js
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Retool from "react-retool";

const DashboardPage = () => {
  useDocumentTitle("Dashboard");

  const { user } = useAuth0();
  const [businessId, setBusinessId] = useState("");

  useEffect(() => {
    const storedBusinessId = localStorage.getItem("business_id");
    if (storedBusinessId) {
      setBusinessId(storedBusinessId);
    }
  }, []);

  // Exit if user or businessId is not available
  if (!user || !businessId) {
    return null;
  }

  // Data to pass to Retool with the keys 'userEmail' and 'businessId'
  const retoolData = {
    userEmail: user.email,
    businessId: businessId,
  };

  return (
    <PageLayout>
      <Retool
        url="https://app.repbot.ai/p/overview"
        width="100%"
        height="800px"
        data={retoolData}
      />
    </PageLayout>
  );
};

export default DashboardPage;
