/* eslint-disable prettier/prettier */
// src/components/CollapsibleComponent.js
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Collapse, Button, Divider, Typography } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const TaskItem = ({ taskName, isComplete, tabIndex }) => {
  const navigate = useNavigate();
  const statusEmoji = isComplete ? "🟢" : "🔴";

  const handleCompleteClick = () => {
    navigate(`/settings?tab=${tabIndex}`);
  };

  return (
    <div
      style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
    >
      <Typography
        variant="h4"
        style={{
          flexGrow: 1,
          fontSize: "2rem",
          textDecoration: isComplete ? "line-through" : "none",
        }}
      >
        {statusEmoji} {taskName} -{" "}
        <span style={{ textDecoration: isComplete ? "line-through" : "none" }}>
          {isComplete ? "Complete" : "Incomplete"}
        </span>
      </Typography>
      {!isComplete && (
        <Button
          variant="contained"
          style={{ fontSize: "1rem" }}
          onClick={handleCompleteClick}
        >
          Complete
        </Button>
      )}
    </div>
  );
};

const CollapsibleComponent = () => {
  const [open, setOpen] = useState(true);
  const [accountData, setAccountData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    const fetchAccountData = async () => {
      if (isAuthenticated && user) {
        setLoading(true);
        try {
          const response = await axios.get(`/api/fetch_account_data`, {
            params: { email: user.email },
          });
          setAccountData(response.data);
          // If business_id is not in local storage, write it
          if (
            !localStorage.getItem("business_id") &&
            response.data.business_id
          ) {
            localStorage.setItem("business_id", response.data.business_id);
          }
        } catch (error) {
          console.error("Error fetching account data:", error);
          setAccountData(null); // Set to null to signify an error or no data
        } finally {
          setLoading(false);
        }
      }
    };

    fetchAccountData();
  }, [user, isAuthenticated]);

  const handleToggle = () => {
    setOpen(!open);
  };

  if (loading) {
    return <p>Loading account data...</p>;
  }

  if (accountData && !accountData.connected) {
    return (
      <div>
        <Button onClick={handleToggle}>
          {open ? "Hide Details" : "Show Details"}
        </Button>
        <Collapse in={open}>
          <div>
            <TaskItem
              taskName="Integration"
              isComplete={accountData.integration_setup}
              tabIndex={1}
            />
            <Divider />
            <TaskItem
              taskName="Review Response Tone"
              isComplete={accountData.tone_setup}
              tabIndex={0}
            />
            <Divider />
            <TaskItem
              taskName="Support Method Selection"
              isComplete={accountData.supportmethod_setup}
              tabIndex={0}
            />
          </div>
        </Collapse>
      </div>
    );
  }

  return null; // If connected is true or accountData is null, don't render anything
};

export default CollapsibleComponent;
