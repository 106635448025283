/* eslint-disable prettier/prettier */
// src/pages/gmb-integration.js
import React from "react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useGMBIntegration from "../hooks/useGMBIntegration";
import LocationListRetrieval from "../components/LocationListRetrieval";
import { useAuth0 } from "@auth0/auth0-react";

const GMBIntegrationPage = () => {
  useDocumentTitle("GMB Integration");

  const { isAuthenticated } = useAuth0();

  // Fetch GMB Integration . This will run regardless of authentication state,
  // but you can handle the behavior of the hook itself if you want to prevent data fetching.
  const locations = useGMBIntegration();

  // If the user is not authenticated, show a message
  if (!isAuthenticated) {
    return <p>You must be logged in to view this page.</p>;
  }

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          GMB Integration
        </h1>
        <div className="content__body">
          {locations ? (
            <LocationListRetrieval locations={locations} />
          ) : (
            <p>Loading business location names</p>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default GMBIntegrationPage;
