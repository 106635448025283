/* eslint-disable prettier/prettier */
// src/components/ui/ChatView.js
import React from 'react';
import ChatBubble from '../ChatComponents/ChatBubble';  // Update the import path

const ChatView = ({ selectedReview }) => {
  // Check if a review has been selected
  if (selectedReview) {
    return (
      <div className="right-column-review_grid">
        {/* Render the ChatBubble component and pass the selectedReview as a prop */}
        <ChatBubble selectedReview={selectedReview} />
      </div>
    );
  } else {
    return (
      <div className="right-column-review_grid">
        {/* Display a message if no review has been selected */}
        <p>Select a review to see details.</p>
      </div>
    );
  }
};

export default ChatView;
