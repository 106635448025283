/* eslint-disable prettier/prettier */
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { MobileNavBarTab } from "./mobile-nav-bar-tab";

export const MobileNavBarTabs = ({ handleClick }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="mobile-nav-bar__tabs">
      {isAuthenticated && (
        <>
          <MobileNavBarTab
            path="/dashboard"
            label="Dashboard"
            handleClick={handleClick}
          />
          <MobileNavBarTab
            path="/reviews"
            label="Reviews"
            handleClick={handleClick}
          />
          <MobileNavBarTab
            path="/add-business"
            label="Add Business"
            handleClick={handleClick}
          />
          <MobileNavBarTab
            path="/google-profile"
            label="Google Profile"
            handleClick={handleClick}
          />
          <MobileNavBarTab
            path="/negative-review-defender"
            label="Negative Review Defender"
            handleClick={handleClick}
          />
          <MobileNavBarTab
            path="/admin"
            label="Admin"
            handleClick={handleClick}
          />
        </>
      )}
    </div>
  );
};
