/* eslint-disable prettier/prettier */
// src/pages/GoogleProfile.js
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useGoogleProfileData from "../hooks/useGoogleProfileData";
import ManageProfile from "../components/ManageProfile";
import GeneratedByGoogle from "../components/GeneratedByGoogle";
import AuthenticatedContent from "../components/ui/AuthenticatedContent";
import { Grid } from "@mui/material"; // import Grid here
import "../styles/GoogleProfile.css"; // Import the new CSS file here

const GoogleProfile = () => {
  const { isAuthenticated } = useAuth0();
  useDocumentTitle("Google Profile");

  const initialBusinessId =
    localStorage.getItem("business_id") || "default_business_id";
  const googleProfileData = useGoogleProfileData(initialBusinessId);

  if (!isAuthenticated) {
    return null;
  }

  if (!googleProfileData) {
    return "Loading...";
  }

  return (
    <PageLayout>
      <AuthenticatedContent>
        <div className="content-layout google-profile">
          <h1 id="page-title" className="content__title">
            Google Profile
          </h1>
          <div className="content__body">
            <Grid container spacing={3}>
              <Grid item xs={6} className="custom-grid-item">
                {" "}
                {/* similar class from OverviewPage.js */}
                <ManageProfile profileData={googleProfileData} />
              </Grid>
              <Grid item xs={6} className="custom-grid-item">
                {" "}
                {/* similar class from OverviewPage.js */}
                <GeneratedByGoogle profileData={googleProfileData} />
              </Grid>
            </Grid>
          </div>
        </div>
      </AuthenticatedContent>
    </PageLayout>
  );
};

export default GoogleProfile;
