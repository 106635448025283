/* eslint-disable prettier/prettier */
// src/components/ui/RatingsBarChart.js

import React, { useState, useEffect, useRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
          color: "black",
        }}
      >
        <p>{`${label} ★ : ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

const RatingsBarChart = ({ ratings }) => {
  const containerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);

  useEffect(() => {
    function updateWidth() {
      if (containerRef.current) {
        setChartWidth(containerRef.current.offsetWidth);
      }
    }
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const formattedData = ratings.map((item) => ({
    name: item.rating,
    Reviews: item.count,
  }));

  const customTickFormatter = (tickItem) => {
    return `${tickItem} ★`;
  };

  const barColors = ["#FF0000", "#FF7F00", "#FFFF00", "#7FFF00", "#00FF00"];

  return (
    <div ref={containerRef}>
      <h3 className="ratings-h3">Reviews per Rating</h3>
      <BarChart
        width={chartWidth}
        height={300}
        data={formattedData}
        layout="vertical"
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis
          dataKey="name"
          type="category"
          tickFormatter={customTickFormatter}
        />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="Reviews">
          {formattedData.map((entry, index) => (
            <Cell key={index} fill={barColors[index % barColors.length]} />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
};

export default RatingsBarChart;
