/* eslint-disable prettier/prettier */
// src/components/ui/filters/FlagFilter.js
import React from "react";

const FlagFilter = ({ flagged, onSelectFlagged }) => {
  return (
    <div className="flag-filter">
      <label>Flagged: </label>
      <select value={flagged} onChange={(e) => onSelectFlagged(e.target.value)}>
        <option value="All">All</option>
        <option value="Yes">Flagged</option>
        <option value="No">Not Flagged</option>
      </select>
    </div>
  );
};

export default FlagFilter;
