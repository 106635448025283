/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Retool from "react-retool";

export const ProfilePage = () => {
  const { user } = useAuth0();
  const [businessId, setBusinessId] = useState("");

  useDocumentTitle("Profile");

  useEffect(() => {
    const storedBusinessId = localStorage.getItem("business_id");
    if (storedBusinessId) {
      setBusinessId(storedBusinessId);
    }
  }, []);

  if (!user || !businessId) {
    return null;
  }

  // Data to pass to Retool with the keys 'userEmail' and 'businessId'
  const retoolData = {
    userEmail: user.email,
    businessId: businessId, // Add businessId to the data object
  };

  return (
    <PageLayout>
      <h1 id="page-title" className="content__title">
        Profile Page
      </h1>
      <Retool
        url="https://app.repbot.ai/p/overview"
        width="100%"
        height="800px"
        data={retoolData} // Passing the updated data object
      />
    </PageLayout>
  );
};

export default ProfilePage;
