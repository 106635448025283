/* eslint-disable prettier/prettier */
// src/pages/reviews.js
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { useReviewAndFilter } from "../hooks/useReviewAndFilter";
import ReviewFilter from "../components/ui/ReviewFilter";
import ReviewsLayout from "./reviews/ReviewsLayout";
import FilteredReviewGrid from "./reviews/FilteredReviewGrid";
import ApproveReviewsCTA from "../components/ui/ApproveReviewsCTA";

import "../styles/components/reviews.css";

const Reviews = () => {
  const { isAuthenticated } = useAuth0();

  const [selectedReviewIds, setSelectedReviewIds] = useState([]); // New state for selected review IDs

  const {
    selectedReview,
    setSelectedReview,
    selectedRating,
    setSelectedRating,
    sortByDate,
    setSortByDate,
    dateRange,
    setDateRange,
    responded,
    setResponded,
    flagged,
    setFlagged,
    filteredReviews,
  } = useReviewAndFilter(
    localStorage.getItem("business_id") || "default_business_id"
  );

  useDocumentTitle("Reviews");

  if (!isAuthenticated) {
    return null;
  }

  return (
    <PageLayout>
      <ReviewsLayout>
        <ReviewFilter
          selectedRating={selectedRating}
          onSelectRating={setSelectedRating}
          sortByDate={sortByDate}
          onSortByDate={setSortByDate}
          dateRange={dateRange}
          onSelectDateRange={setDateRange}
          responded={responded}
          onSelectResponded={setResponded}
          flagged={flagged}
          onSelectFlagged={setFlagged}
        />
        <FilteredReviewGrid
          filteredReviews={filteredReviews}
          setSelectedReview={setSelectedReview}
          selectedReview={selectedReview}
          setSelectedReviewIds={setSelectedReviewIds} // Pass the setter for selected review IDs
        />
        {selectedReviewIds.length > 0 && (
          <ApproveReviewsCTA selectedReviewIds={selectedReviewIds} />
        )}{" "}
        {/* Render the new component */}
      </ReviewsLayout>
    </PageLayout>
  );
};

export default Reviews;
