/* eslint-disable prettier/prettier */
// src/components/ManageProfile.js
import React, { useState } from "react";
import Modal from "./modal";
import { generateDescription } from "../services/generateDescription";
import "../styles/ManageProfile.css";

const ManageProfile = ({ profileData }) => {
  const actualData =
    profileData?.gmb_profile?.[0]?.outscraper_gmb_profile || {};
  const {
    name = "N/A",
    city = "N/A",
    full_address = "N/A",
    type = "N/A",
    description = "N/A",
    category = "N/A",
    subtypes = "N/A",
  } = actualData;

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [autoDescription, setAutoDescription] = useState(null);

  const handleModalClose = (updatedDescription) => {
    console.log(`Updated Description: ${updatedDescription}`);
    setShowModal(false);
    setAutoDescription(null); // Reset autoDescription when modal is closed
  };

  const handleAutoGenerate = async () => {
    console.log("Starting autogeneration...");
    setLoading(true);

    try {
      const generated = await generateDescription(name, city, category);
      console.log("Received generated data:", generated);

      if (generated) {
        console.log("Setting autogenerated description and showing modal...");
        setAutoDescription(generated); // Adjusted this line
        setShowModal(true);
      } else {
        console.log("No description found in the generated data.");
      }
    } catch (error) {
      console.log("Error during autogeneration:", error);
    }

    setLoading(false);
    console.log("Autogeneration finished.");
  };

  return (
    <div className="manage-profile">
      <h2 className="manage-profile__header h2-header">Manage Profile</h2>
      <p>
        <strong>Name:</strong> {name}
      </p>
      <p>
        <strong>City:</strong> {city}
      </p>
      <p>
        <strong>Address:</strong> {full_address}
      </p>
      <p>
        <strong>Business Type:</strong> {type}
      </p>
      <div className="description-container">
        <p>
          <strong>Description:</strong> {description}
        </p>
        <div className="description-buttons">
          <button
            className="description-button btn-edit"
            onClick={() => setShowModal(true)}
          >
            Edit
          </button>
          <button
            className="description-button btn-autogenerate"
            onClick={handleAutoGenerate}
          >
            {loading ? "Loading..." : "Autogenerate"}
          </button>
        </div>
      </div>
      <p>
        <strong>Category:</strong> {category}
      </p>
      <p>
        <strong>Subcategories:</strong> {subtypes}
      </p>
      {showModal && (
        <Modal
          label="Description"
          placeholder="Enter description"
          initialValue={autoDescription || description}
          onClose={handleModalClose}
          textareaClass="textarea-description"
        />
      )}
    </div>
  );
};

export default ManageProfile;
