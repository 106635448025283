/* eslint-disable prettier/prettier */
// src/pages/settings-page.js
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import SettingsTabs from "../components/SettingsTabs";

const SettingsPage = () => {
  const { isAuthenticated } = useAuth0();
  useDocumentTitle("Settings");

  // Use search params to read query parameters
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    // Get the tab query parameter and convert it to a number
    const tabParam = searchParams.get("tab");
    if (tabParam) {
      setActiveTab(parseInt(tabParam, 10));
    }
  }, [searchParams]);

  if (!isAuthenticated) {
    return <p>Please log in to view this page.</p>;
  }

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Settings
        </h1>
        <div className="content__body">
          {/* Pass the activeTab state to the SettingsTabs component */}
          <SettingsTabs activeTab={activeTab} />
        </div>
      </div>
    </PageLayout>
  );
};

export default SettingsPage;
