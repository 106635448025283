/* eslint-disable prettier/prettier */
// src\app.js
import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import SettingsPage from "./pages/settings-page";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import ProtectedPage from "./pages/protected-page";
import { PublicPage } from "./pages/public-page";
import Reviews from "./pages/reviews";
import DashboardPage from "./pages/dashboard-page";
import AddBusiness from "./pages/add-business";
import GoogleProfile from "./pages/google-profile";
import NegativeReviewDefender from "./pages/negative-review-defender";
import SupportPage from "./pages/support";
import Insights from "./pages/insights";
import AdminReviewPanel from "./pages/AdminReviewPanel";
import GMBIntegrationPage from "./pages/gmb-integration";

export const App = () => {
  const { isLoading, user } = useAuth0();

  useEffect(() => {
    if (user && window.Intercom) {
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "epkx48iw",
        name: user.name,
        email: user.email,
        created_at: new Date(user.updated_at).getTime() / 1000,
      });
    }
  }, [user]);

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/profile"
        element={<AuthenticationGuard component={ProfilePage} />}
      />
      <Route path="/public" element={<PublicPage />} />
      <Route
        path="/protected"
        element={<AuthenticationGuard component={ProtectedPage} />}
      />
      <Route
        path="/reviews"
        element={<AuthenticationGuard component={Reviews} />}
      />
      <Route
        path="/dashboard"
        element={<AuthenticationGuard component={DashboardPage} />}
      />
      <Route
        path="/add-business"
        element={<AuthenticationGuard component={AddBusiness} />}
      />
      <Route
        path="/google-profile"
        element={<AuthenticationGuard component={GoogleProfile} />}
      />
      <Route
        path="/negative-review-defender"
        element={<AuthenticationGuard component={NegativeReviewDefender} />}
      />
      <Route
        path="/support"
        element={<AuthenticationGuard component={SupportPage} />}
      />
      <Route
        path="/insights"
        element={<AuthenticationGuard component={Insights} />}
      />
      <Route
        path="/AdminReviewPanel"
        element={<AuthenticationGuard component={AdminReviewPanel} />}
      />
      <Route
        path="/settings"
        element={<AuthenticationGuard component={SettingsPage} />}
      />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route
        path="/gmb-integration"
        element={<AuthenticationGuard component={GMBIntegrationPage} />}
      />
    </Routes>
  );
};
