// src\components\StatBox.js

import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const StatBox = ({ label, count, emoji = "🔢", description }) => {
  return (
    <Card className="stat-box">
      <CardContent>
        <Typography variant="body1" component="div" align="center">
          {emoji}
        </Typography>
        <Typography variant="h6" component="div" align="center">
          {label}
        </Typography>
        <Typography variant="h4" component="div" align="center">
          {count || 0}
        </Typography>
        <Typography
          variant="body2"
          component="div"
          align="center"
          style={{ marginTop: "8px" }}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StatBox;
