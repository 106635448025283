/* eslint-disable prettier/prettier */
// src/components/ui/filters/DateSortFilter.js
import React from "react";

const DateSortFilter = ({ sortByDate, onSortByDate }) => {
  return (
    <div className="date-sort-filter">
      <label>Sort by Date: </label>
      <select value={sortByDate} onChange={(e) => onSortByDate(e.target.value)}>
        <option value="latest">Latest</option>
        <option value="oldest">Oldest</option>
      </select>
    </div>
  );
};

export default DateSortFilter;
