/* eslint-disable prettier/prettier */
// src/pages/add-business.js
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from '../hooks/useDocumentTitle';

const AddBusiness = () => {
  const { isAuthenticated } = useAuth0();
  useDocumentTitle('Add Business');

  if (!isAuthenticated) {
    return null;
  }

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Add Business
        </h1>
        <div className="content__body">
          <p id="page-description"></p>
        </div>
      </div>
    </PageLayout>
  );
};

export default AddBusiness;
