import React from "react";
import { PageFooterHyperlink } from "./page-footer-hyperlink";

export const PageFooter = () => {
  return (
    <footer className="page-footer">
      <div className="page-footer-grid">
        <div className="page-footer-grid__brand">
          <div className="page-footer-brand">
            <img
              className="page-footer-brand__logo"
              src="https://res.cloudinary.com/sm-gcs/image/upload/t_media_lib_thumb/v1689068694/RepBot/star_admroi.png"
              alt="RepBot.ai Star Graphic"
              width="50"
              height="50"
            />
            <PageFooterHyperlink path="https://repbot.ai">
              RepBot.ai
            </PageFooterHyperlink>
          </div>
        </div>
      </div>
    </footer>
  );
};
