/* eslint-disable prettier/prettier */
// src/services/notes.service.js
import fetch from "node-fetch";

export const updateNotes = async (reviewId, notes) => {
  try {
    const apiUrl = `https://notes.repbot.workers.dev/?reviewId=${reviewId}&notes=${encodeURIComponent(notes)}`;

    const res = await fetch(apiUrl, {
      method: 'GET' // Using GET method to align with your existing Web Worker
    });
    
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};
