/* eslint-disable prettier/prettier */
// src/components/page-layout.js
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { NavBar } from "./navigation/desktop/nav-bar";
import { SecondaryNavBar } from "./navigation/desktop/secondary-nav-bar";
import { MobileNavBar } from "./navigation/mobile/mobile-nav-bar";
import { PageFooter } from "./page-footer";
import CollapsibleComponent from "./CollapsibleComponent"; // Import the CollapsibleComponent

export const PageLayout = ({ children }) => {
  const { isAuthenticated } = useAuth0();

  // Removed useState and useEffect related to scroll behavior

  return (
    <div className="page-layout">
      <NavBar />
      {isAuthenticated && <SecondaryNavBar />}
      <MobileNavBar />
      <CollapsibleComponent /> {/* Include the CollapsibleComponent here */}
      <div className="page-layout__content">{children}</div>
      <PageFooter />
    </div>
  );
};
