/* eslint-disable prettier/prettier */
// src/hooks/useReviewLogic.js
import { useState } from 'react';

const useReviewLogic = (initialSelected) => {
  const [isSelected, setIsSelected] = useState(initialSelected);

  const handleCheckboxClick = (reviewId, onCheckboxChange) => {
    setIsSelected(!isSelected);
    onCheckboxChange(reviewId);
  };

  return { isSelected, handleCheckboxClick };
};

export default useReviewLogic;
