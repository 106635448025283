/* eslint-disable prettier/prettier */
// src\components\ChatComponents\ReviewHeader.js
import React from 'react';
import './ReviewHeader.css'; // Import the CSS if you create a new CSS file for this component

const ReviewHeader = ({ profilePhoto, reviewerName, rating, reviewDate }) => (
  <div className="review-header">
    <div className="header-item logo">
      <img src={profilePhoto} alt={reviewerName} />
    </div>
    <div className="header-item name">
      <h4>{reviewerName}</h4>
    </div>
    <div className="header-item rating">
      <p>{'⭐'.repeat(Number(rating))}</p>
    </div>
    <div className="header-item date">
      {/* Formatting the date */}
      <p>{new Date(reviewDate).toLocaleDateString()}</p>
    </div>
  </div>
);

export default ReviewHeader;
