/* eslint-disable prettier/prettier */
// src/pages/reviews/FilteredReviewGrid.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import ReviewList from "../../components/ui/ReviewList";
import ChatView from "../../components/ui/ChatView";
import ApproveReviewsCTA from "../../components/ui/ApproveReviewsCTA";
import useLocalStorage from "../../hooks/useLocalStorage";
import "../../styles/components/reviews.css";

const FilteredReviewGrid = ({
  filteredReviews,
  setSelectedReview,
  selectedReview,
}) => {
  const [selectedReviewIds, setSelectedReviewIds] = useState([]);
  const [savedStatus, setSavedStatus] = useState(false);
  const [reviewStorage, setReviewStorage] = useLocalStorage("reviews", {});

  const handleSave = () => {
    console.log("Selected Reviews:", selectedReviewIds);
    const updatedReviewStorage = {
      ...reviewStorage,
      [selectedReview.review_id]: "approved",
    };
    setReviewStorage(updatedReviewStorage);
    setSavedStatus(true);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={4} className="review-list-container">
        <ReviewList
          reviews={filteredReviews}
          setSelectedReview={setSelectedReview}
          setSelectedReviewIds={setSelectedReviewIds}
        />
      </Grid>
      <Grid item xs={8} className="chat-view-container">
        <ChatView selectedReview={selectedReview} />
      </Grid>
      {selectedReviewIds.length > 0 && !savedStatus && (
        <ApproveReviewsCTA
          selectedReviewIds={selectedReviewIds}
          handleSave={handleSave}
        />
      )}
    </Grid>
  );
};

FilteredReviewGrid.propTypes = {
  filteredReviews: PropTypes.array.isRequired,
  setSelectedReview: PropTypes.func.isRequired,
  selectedReview: PropTypes.object,
};

export default FilteredReviewGrid;
