/* eslint-disable prettier/prettier */
import React from "react";

export const HeroBanner = () => {
  return (
    <div className="hero-banner hero-banner--pink-yellow">
      <h1 className="hero-banner__headline">
        Automate Your Reputation Management
        <br /> Win Customers Effortlessly
      </h1>
      <p className="hero-banner__description">
        The only all-in-one automated platform to monitor customer reviews,
        generate customized responses, and publish them on Google and Facebook,
        all without lifting a finger.
      </p>
    </div>
  );
};
