/* eslint-disable prettier/prettier */
// src/hooks/useReviewAndFilter.js
import { useState, useEffect } from "react";
import { fetchReviews } from "../services/review.service";

export const useReviewAndFilter = (initialBusinessId) => {
  const [selectedReview, setSelectedReview] = useState(null);
  const [selectedRating, setSelectedRating] = useState("All");
  const [sortByDate, setSortByDate] = useState("Newest");
  const [dateRange, setDateRange] = useState("All");
  const [responded, setResponded] = useState("All");
  const [flagged, setFlagged] = useState("All");

  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchReviews(initialBusinessId);
      // Check if the response itself is an array before setting it to state
      if (Array.isArray(response)) {
        console.log("Fetched reviews array:", response); // Log the fetched array for debugging
        setReviews(response);
      } else {
        console.error("Fetched data is not an array:", response);
        setReviews([]); // Set to empty array if data is not valid
      }
    };
    fetchData();
  }, [initialBusinessId]);

  // Filtering logic
  let filtered = [...reviews];

  // Filter by rating
  if (selectedRating !== "All") {
    filtered = filtered.filter(
      (review) => parseInt(review.rating, 10) === parseInt(selectedRating, 10)
    );
  }

  // Sort by date
  if (sortByDate === "Newest") {
    filtered.sort((a, b) => new Date(b.review_date) - new Date(a.review_date));
  } else {
    filtered.sort((a, b) => new Date(a.review_date) - new Date(b.review_date));
  }

  // Filter by date range
  const today = new Date();
  if (dateRange !== "All") {
    const months = {
      last_month: 1,
      last_3_months: 3,
      last_6_months: 6,
      last_12_months: 12,
    };
    const limitDate = new Date();
    limitDate.setMonth(today.getMonth() - months[dateRange]);
    filtered = filtered.filter((review) => {
      const reviewDate = new Date(review.review_date);
      return reviewDate >= limitDate;
    });
  }

  // Filter by responded
  if (responded !== "All") {
    filtered = filtered.filter(
      (review) => (review.review_response !== null) === (responded === "Yes")
    );
  }

  // Filter by flagged
  if (flagged !== "All") {
    filtered = filtered.filter(
      (review) => review.flagged === (flagged === "Yes")
    );
  }

  return {
    selectedReview,
    setSelectedReview,
    selectedRating,
    setSelectedRating,
    sortByDate,
    setSortByDate,
    dateRange,
    setDateRange,
    responded,
    setResponded,
    flagged,
    setFlagged,
    filteredReviews: filtered,
  };
};
