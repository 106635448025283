/* eslint-disable prettier/prettier */
// src/services/negReviews.service.js

import fetch from "node-fetch";

export const negReviews = async (businessId) => {
  try {
    const res = await fetch(
      `https://negreviews.repbot.workers.dev/?businessId=${businessId}`
    );
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};
