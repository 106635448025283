/* eslint-disable prettier/prettier */// src/hooks/useNegativeReviews.js
import { useState, useEffect } from "react";
import { negReviews } from "../services/negReviews.service";

const useNegativeReviews = (businessId) => {
  const [stats, setStats] = useState(null);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchNegReviews = async () => {
      const data = await negReviews(businessId);

      if (data) {
        setStats(data.stats);
        const sortedReviews = data.reviews.sort((a, b) => {
          if (a.status && !b.status) return -1;
          if (!a.status && b.status) return 1;
          return 0;
        });
        setReviews(sortedReviews);
      }
    };

    fetchNegReviews();
  }, [businessId]);

  return { stats, reviews };
};

export default useNegativeReviews;
