/* eslint-disable prettier/prettier */
// src/components/LocationSelectionForm.js
import React from "react";

const LocationSelectionForm = ({
  locations,
  selectedLocation,
  onChange,
  onSubmit,
}) => (
  <form onSubmit={onSubmit}>
    {locations.map((location, index) => (
      <div key={index}>
        <input
          type="radio"
          name="location"
          value={location.title}
          checked={
            selectedLocation && selectedLocation.title === location.title
          }
          onChange={() => onChange(location)}
        />
        {location.title}
      </div>
    ))}
    <button type="submit">SUBMIT</button>
  </form>
);

export default LocationSelectionForm;
