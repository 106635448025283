/* eslint-disable prettier/prettier */
// src/hooks/useGoogleProfileData.js
import { useEffect, useState } from "react";
import { fetchGoogleProfile } from "../services/fetchGoogleProfile";

const useGoogleProfileData = (businessId) => {
  const [googleProfileData, setGoogleProfileData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchGoogleProfile(businessId);
      setGoogleProfileData(data);
    };

    fetchData();
  }, [businessId]);

  return googleProfileData;
};

export default useGoogleProfileData;
