/* eslint-disable prettier/prettier */
// src\pages\negative-review-defender.js
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useNegativeReviews from "../hooks/useNegativeReviews";
import StatBox from "../components/StatBox";
import ReviewList from "../components/ReviewList";
import "../styles/components/negative-review-defender.css";

const NegativeReviewDefender = () => {
  const { isAuthenticated } = useAuth0();

  const initialBusinessId =
    localStorage.getItem("business_id") || "default_business_id";
  const { stats, reviews } = useNegativeReviews(initialBusinessId);

  useDocumentTitle("Negative Review Defender");

  // Feature flag to control the display of the ReviewList component
  const showReviewList = false;

  if (!isAuthenticated) {
    return null;
  }

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Negative Review Defender
        </h1>
        <div className="stats-section">
          <StatBox
            emoji="⭐"
            label="Total 1-Star Reviews"
            count={stats?.["Total reviews"]}
            description="The total number of 1-star reviews received."
          />
          <StatBox
            emoji="⚠️"
            label="Total Flagged Reviews"
            count={stats?.["Flagged reviews"]}
            description="Reviews flagged as potentially violating Google's policy."
          />
          <StatBox
            emoji="📢"
            label="Total Reported Reviews"
            count={stats?.["Repbot reported reviews"]}
            description="Reviews that have been reported to Google for policy violations."
          />
          <StatBox
            emoji="✅"
            label="Successful Removals"
            count={stats?.["Removed reviews"]}
            description="Reviews that were successfully removed after being reported by RepBot."
          />
        </div>
        {showReviewList && <ReviewList reviews={reviews} />}
      </div>
    </PageLayout>
  );
};

export default NegativeReviewDefender;
