/* eslint-disable prettier/prettier */
// src/services/flagged.service.js
import fetch from "node-fetch";

export const toggleFlagStatus = async (reviewId, flagged) => {
  try {
    // Construct the URL for the Vercel Edge function endpoint
    // Make sure to use the correct URL for your Vercel deployment
    const vercelApiUrl = new URL(
      "/api/toggleFlagStatus",
      window.location.origin
    );
    vercelApiUrl.searchParams.append("reviewId", reviewId);
    vercelApiUrl.searchParams.append("flagged", flagged);

    const res = await fetch(vercelApiUrl.href, {
      method: "POST", // Assuming the method should be POST to update data
      headers: {
        "Content-Type": "application/json",
        // Include other headers as needed, for example, authorization headers
      },
      // If additional data needs to be sent in the body, include it here
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    // If you don't need to return the updated row, simply return a success status
    return { success: true };
  } catch (error) {
    console.error("Error:", error);
    return { success: false, error: error.message };
  }
};
