/* eslint-disable prettier/prettier */
import React from "react";

const ResponseBubble = ({ reviewResponse }) => (
  <div className="response-bubble">
    <p>{reviewResponse}</p>
  </div>
);

export default ResponseBubble;
