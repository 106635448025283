/* eslint-disable prettier/prettier */
// src/components/ui/ReviewList.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import ReviewCard from "./ReviewCard";

const ReviewList = ({ reviews, setSelectedReview, setSelectedReviewIds }) => {
  const [selectedReviewIds, setSelectedReviewIdsLocal] = useState(new Set());

  const handleReviewClick = (review) => {
    setSelectedReview(review);
  };

  const handleCheckboxChange = (reviewId) => {
    const newSelectedReviewIds = new Set(selectedReviewIds);
    if (newSelectedReviewIds.has(reviewId)) {
      newSelectedReviewIds.delete(reviewId);
    } else {
      newSelectedReviewIds.add(reviewId);
    }
    setSelectedReviewIdsLocal(newSelectedReviewIds);
    setSelectedReviewIds(Array.from(newSelectedReviewIds));  
  };

  return (
    <div className="left-column">
      {reviews.map((review) => (
        <ReviewCard
          key={review.review_id}
          review={review}
          isSelected={selectedReviewIds.has(review.review_id)}
          onCheckboxChange={handleCheckboxChange}
          onClick={() => handleReviewClick(review)}
        />
      ))}
    </div>
  );
};

ReviewList.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedReview: PropTypes.func.isRequired,
  setSelectedReviewIds: PropTypes.func.isRequired,
};

export default ReviewList;
