/* eslint-disable prettier/prettier */
// src/components/ChatComponents/ChatBubble.js
import React, { useState, useEffect } from "react";
import ReviewHeader from "./ReviewHeader";
import MessageBubble from "./MessageBubble";
import ResponseBubble from "./ResponseBubble";
import ProposedResponseBubble from "./ProposedResponseBubble"; // Import the new component
import handleFlagToggle from "./handleFlagToggle";

import "../../styles/components/reviews.css";

const ChatBubble = ({ selectedReview }) => {
  const [isFlagged, setIsFlagged] = useState(selectedReview.flagged);

  useEffect(() => {
    const flaggedStatuses =
      JSON.parse(localStorage.getItem("flaggedStatuses")) || {};
    setIsFlagged(
      flaggedStatuses[selectedReview.review_id] || selectedReview.flagged
    );
  }, [selectedReview.review_id, selectedReview.flagged]);

  return (
    <>
      <div className="chat-bubble">
        <ReviewHeader
          profilePhoto={selectedReview.profile_photo_url}
          reviewerName={selectedReview.reviewer_name}
          rating={selectedReview.rating}
          reviewDate={selectedReview.review_date}
        />
        <MessageBubble
          key={selectedReview.review_id}
          reviewText={selectedReview.review_text}
          isFlagged={isFlagged}
          handleFlagToggle={() =>
            handleFlagToggle(selectedReview, isFlagged, setIsFlagged)
          }
          reviewNotes={selectedReview.review_notes}
          reviewId={selectedReview.review_id}
        />
      </div>
      {selectedReview.review_response && (
        <ResponseBubble reviewResponse={selectedReview.review_response} />
      )}
      {selectedReview.proposed_response && (
        <ProposedResponseBubble
          proposedResponse={selectedReview.proposed_response}
        />
      )}
    </>
  );
};

export default ChatBubble;
