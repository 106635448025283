/* eslint-disable prettier/prettier */
// src/hooks/useGMBIntegration.js
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";

const useGMBIntegration = () => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const [authCode, setAuthCode] = useState(null); // State to store the code

  useEffect(() => {
    if (isAuthenticated) {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get("code");

      if (code) {
        setAuthCode(code); // Setting the code to the state
        console.log("Extracted code from search parameters:", code);
      } else {
        console.log("Code not present in search parameters.");
      }
    } else {
      console.log("User not authenticated. Skipping code extraction.");
    }
  }, [isAuthenticated, location]);

  return { code: authCode }; // Return the code
};

export default useGMBIntegration;
