/* eslint-disable prettier/prettier */
// src/components/ui/filters/ResponseFilter.js
import React from "react";

const ResponseFilter = ({ responded, onSelectResponded }) => {
  return (
    <div className="response-filter">
      <label>Responded: </label>
      <select
        value={responded}
        onChange={(e) => onSelectResponded(e.target.value)}
      >
        <option value="All">All</option>
        <option value="Yes">Responded</option>
        <option value="No">Not Responded</option>
      </select>
    </div>
  );
};

export default ResponseFilter;
