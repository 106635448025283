/* eslint-disable prettier/prettier */
// src/components/ui/ApproveReviewsCTA.js
import React from "react";
import PropTypes from "prop-types";
import fetch from "node-fetch"; // Import fetch for making the API call
import "../styles/components/approveReviewsCTA.css";

const ApproveReviewsCTA = ({ selectedReviewIds }) => {
  const handleSaveClick = async () => {
    console.log("Selected review IDs:", selectedReviewIds.join(", "));

    // Make a POST request to the new edge function
    try {
      const response = await fetch("/api/save-approved-reviews", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ reviewIds: selectedReviewIds }),
      });

      const result = await response.json();
      console.log(result);

      // Trigger a page refresh on successful update
      if (response.ok) {
        window.location.reload();
      } else {
        throw new Error("Failed to save approved reviews");
      }
    } catch (error) {
      console.error("Error saving approved reviews:", error);
    }
  };

  return (
    <div className="approve-reviews-cta-container fixed-bottom">
      <button onClick={handleSaveClick} className="approve-reviews-cta-button">
        SAVE
      </button>
    </div>
  );
};

ApproveReviewsCTA.propTypes = {
  selectedReviewIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ApproveReviewsCTA;
