/* eslint-disable prettier/prettier */
// src/components/GeneratedByGoogle.js
import React from "react";
import "../styles/GeneratedByGoogle.css"; // Import the new CSS file

// Define the safeGet function right here in this file
const safeGet = (obj, path, defaultValue) => {
  return (
    path.split(".").reduce((acc, part) => {
      return acc ? acc[part] : null;
    }, obj) || defaultValue
  );
};

// Define sub-components
const Ratings = ({ data }) => (
  <p>
    <strong>Rating:</strong> {safeGet(data, "rating", "N/A")}
  </p>
);
const Reviews = ({ data }) => (
  <p>
    <strong>Reviews:</strong> {safeGet(data, "reviews", "N/A")}
  </p>
);
const ReviewTags = ({ data }) => (
  <p>
    <strong>Review Tags:</strong>{" "}
    {safeGet(data, "reviews_tags", []).join(", ") || "N/A"}
  </p>
);
const ServiceOptions = ({ data }) => (
  <p>
    <strong>Service Options:</strong>
    Debit cards:{" "}
    {safeGet(data, "about.Payments.Debit cards", "No") === true ? "Yes" : "No"},
    Credit cards:{" "}
    {safeGet(data, "about.Payments.Credit cards", "No") === true ? "Yes" : "No"}
  </p>
);
const WorkingHours = ({ data }) => (
  <p>
    <strong>Hours:</strong>{" "}
    {Object.entries(safeGet(data, "working_hours", {})).map(
      ([day, hours], index) => (
        <div key={index}>
          {day}: {hours || "N/A"}
        </div>
      )
    )}
  </p>
);

// Main component
const GeneratedByGoogle = ({ profileData }) => {
  const actualData = profileData.gmb_profile?.[0]?.outscraper_gmb_profile || {};

  return (
    <div className="generated-by-google">
      <h2 className="generated-by-google__header h2-header">
        Generated by Google
      </h2>
      <Ratings data={actualData} />
      <Reviews data={actualData} />
      <ReviewTags data={actualData} />
      <ServiceOptions data={actualData} />
      <WorkingHours data={actualData} />
    </div>
  );
};

export default GeneratedByGoogle;
