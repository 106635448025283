/* eslint-disable prettier/prettier */
import { toggleFlagStatus } from '../../services/flagged.service';

const handleFlagToggle = async (selectedReview, isFlagged, setIsFlagged) => {
  const flaggedStatuses = JSON.parse(localStorage.getItem('flaggedStatuses')) || {};
  const newFlagStatus = !isFlagged;
  flaggedStatuses[selectedReview.review_id] = newFlagStatus;
  localStorage.setItem('flaggedStatuses', JSON.stringify(flaggedStatuses));
  await toggleFlagStatus(selectedReview.review_id, newFlagStatus);
  setIsFlagged(newFlagStatus);
  window.location.reload();
};

export default handleFlagToggle;
