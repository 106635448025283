/* eslint-disable prettier/prettier */
// src/components/modal.js
import React, { useRef } from "react";

const Modal = ({
  label,
  placeholder,
  initialValue,
  onClose,
  textareaClass,
}) => {
  const textareaRef = useRef(null);

  const handleConfirm = () => {
    const value = textareaRef.current.value;
    if (value !== null && value !== undefined) {
      onClose(value);
    }
  };

  return (
    <div className="modal active">
      <form>
        <p>
          <label className="description-label">
            {label}:
            <textarea
              ref={textareaRef}
              className={textareaClass}
              placeholder={placeholder}
              defaultValue={initialValue}
              required
              rows="4"
            />
          </label>
        </p>
        <div className="modal-buttons">
          <button
            type="button"
            className="modal-cancel-button"
            onClick={() => onClose(null)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="modal-save-button"
            onClick={handleConfirm}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default Modal;
