/* eslint-disable prettier/prettier */
// src/components/ui/ReviewCard.js
import React from "react";
import useReviewLogic from "../../hooks/useReviewLogic";
import "../../styles/components/reviews.css";

const ReviewCard = ({ review, onClick, onCheckboxChange }) => {
  // Determine whether a response exists for the review
  const isResponded = Boolean(review.review_response);

  // Determine whether the review is already approved
  const isApproved = Boolean(review.approved_response);

  // Check if there is a proposed response
  const hasProposedResponse = Boolean(review.proposed_response);

  const { isSelected, handleCheckboxClick } = useReviewLogic(false);

  return (
    <div
      onClick={onClick}
      className={`review-box ${isResponded ? "responded" : ""}`}
    >
      <div className="review-header">
        <div className="logo">
          <img src={review.profile_photo_url} alt={review.reviewer_name} />
        </div>
        <div className="name">
          <span>{review.reviewer_name}</span>
        </div>
        <div className="rating">
          <span>{"⭐".repeat(Number(review.rating))}</span>
        </div>
        <div className="date">
          <span>{new Date(review.review_date).toLocaleDateString()}</span>
        </div>
      </div>
      <div className="review-excerpt">
        <p>
          {review.review_text
            ? review.review_text.substring(0, 40) + "..."
            : "No review text available"}
        </p>
      </div>
      <div className="review-details">
        <div className="icon-container">
          <div className="flagged-icon">
            {review.flagged && (
              <span role="img" aria-label="flagged">
                🚩 Flagged
              </span>
            )}
          </div>
          <div className="responded-icon">
            {isResponded && (
              <span role="img" aria-label="responded">
                ✅ Responded
              </span>
            )}
          </div>
          {/* Only show the checkbox if the review meets all three conditions */}
          {!isResponded && !isApproved && hasProposedResponse && (
            <div
              className="select-checkbox"
              onClick={() =>
                handleCheckboxClick(review.review_id, onCheckboxChange)
              }
            >
              <>
                <input
                  type="checkbox"
                  className="select-checkbox-input"
                  checked={isSelected}
                />
                <span role="img" aria-label="approve-response-checkbox-text">
                  Approve
                </span>
              </>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
