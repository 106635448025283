/* eslint-disable prettier/prettier */
// src/components/BillingComponent.js

import React from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const BillingComponent = () => {
  return (
    <Box textAlign="center">
      <Typography variant="h3">Secure Billing with Stripe</Typography>
      <Grid container alignItems="center">
        {" "}
        {/* Add alignItems="center" */}
        <Grid item xs={3}>
          <a
            href="https://billing.stripe.com/p/login/14kbJD24SeaJdlS288"
            target="_blank"
            rel="noreferrer"
          >
            <img src="stripe-payment.png" alt="Secure Billing with Stripe" />
          </a>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            We use Stripe&apos;s secure payment portal to process all payments,
            so you can be confident that your billing information is safe and
            secure.
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button
            href="https://billing.stripe.com/p/login/14kbJD24SeaJdlS288"
            target="_blank"
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIcon />}
          >
            VISIT STRIPE
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BillingComponent;
