/* eslint-disable prettier/prettier */
// src/components/navigation/desktop/secondary-nav-bar.js
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { NavBarTab } from "./nav-bar-tab";

export const SecondaryNavBar = () => {
  const { isAuthenticated } = useAuth0();

  // If the user is authenticated, show the navigation tabs
  if (isAuthenticated) {
    return (
      <div className="secondary-nav-bar__container">
        <nav className="secondary-nav-bar">
          <div className="secondary-nav-bar__tabs">
            <NavBarTab
              className="secondary-nav-bar__tab"
              path="/dashboard"
              label="Dashboard"
            />
            <NavBarTab
              className="secondary-nav-bar__tab"
              path="/reviews"
              label="Reviews"
            />
            <NavBarTab
              className="secondary-nav-bar__tab"
              path="/google-profile"
              label="Google Profile"
            />
            <NavBarTab
              className="secondary-nav-bar__tab"
              path="/negative-review-defender"
              label="Negative Review Defender"
            />
          </div>
        </nav>
      </div>
    );
  }

  // If the user is not authenticated, you could return null or some other component
  return null;
};
