/* eslint-disable prettier/prettier */
// src/components/ui/filters/RatingFilter.js
import React from "react";

const RatingFilter = ({ selectedRating, onSelectRating }) => {
  return (
    <div className="rating-filter">
      <label>Rating: </label>
      <select
        value={selectedRating}
        onChange={(e) => onSelectRating(e.target.value)}
      >
        <option value="All">All</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
      </select>
    </div>
  );
};

export default RatingFilter;
