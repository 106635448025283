/* eslint-disable prettier/prettier */
// src/components/ui/AuthenticatedContent.js
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const AuthenticatedContent = ({ children }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <div>
      {isAuthenticated ? children : <div>You need to be authenticated to view this content.</div>}
    </div>
  );
};

export default AuthenticatedContent;
