/* eslint-disable prettier/prettier */
// src\components\CustomerReviewDashboard.js
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Retool from "react-retool";

const CustomerReviewDashboard = () => {
  const { user } = useAuth0();
  const [businessId, setBusinessId] = useState("");

  useEffect(() => {
    // Retrieve business_id from localStorage
    const storedBusinessId = localStorage.getItem("business_id");
    if (storedBusinessId) {
      setBusinessId(storedBusinessId);
    }
  }, [user]); // Ensure this effect runs when user changes

  if (!user || !businessId) {
    // If we don't have the user or the business ID yet, don't render the Retool app
    return <p>Loading...</p>;
  }

  // Data to pass to Retool with the keys 'userEmail' and 'businessId'
  const retoolData = {
    userEmail: user.email,
    businessId: businessId,
  };

  return (
    <Retool
      url="https://app.repbot.ai/p/response-tone"
      width="100%"
      height="800px"
      data={retoolData} // Passing the updated data object
    />
  );
};

export default CustomerReviewDashboard;
