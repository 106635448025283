/* eslint-disable prettier/prettier */
// src/components/navigation/desktop/nav-bar-tabs.js
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { NavBarTab } from "./nav-bar-tab";

export const NavBarTabs = () => {
  const { isAuthenticated } = useAuth0();

  const handleSupportClick = () => {
    if (window.Intercom) {
      window.Intercom("show");
    }
  };

  return (
    <div className="nav-bar__tabs">
      {isAuthenticated && (
        <>
          <NavBarTab path="/settings" label="Settings" />
          <div onClick={handleSupportClick} className="nav-bar__tab">
            Support
          </div>
        </>
      )}
    </div>
  );
};
