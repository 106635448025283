/* eslint-disable prettier/prettier */
// src/components/GenReviewsHowUX.js
import React, { useState } from "react";
import "../styles/components/GenReviewsHowUX.css";

const GenReviewsHowUX = () => {
  const [feedbackType, setFeedbackType] = useState("positive");

  const handleFeedbackClick = (feedbackType) => {
    setFeedbackType(feedbackType);
  };

  const getEmoji = () => {
    if (feedbackType === "positive") {
      return "🥳";
    } else {
      return "☹️";
    }
  };

  return (
    <div className="illustration-container">
      <div className="top-text">
        Customers receive an email or text with a survey invitation link.
      </div>
      <div className="arrow-container">
        <svg className="arrow" viewBox="0 0 50 50">
          <path d="M25,0 L50,25 L25,50 L0,25 Z"></path>
        </svg>
      </div>
      <div className="bottom-section">
        <div className="positive-feedback">
          <h3>Positive feedback</h3>
          <span onClick={() => handleFeedbackClick("positive")} role="button">
            {getEmoji()}
          </span>
          <p>Happy customers are redirected to your Google My Business page.</p>
          <p>
            Your public business listing earns multiple 5-star ratings from real
            customers.
          </p>
        </div>
        <div className="negative-feedback">
          <h3>Negative feedback</h3>
          <span onClick={() => handleFeedbackClick("negative")} role="button">
            {getEmoji()}
          </span>
          <p>
            Unhappy customers answer additional questions directly in our
            software.
          </p>
          <p>
            Your business privately takes action to resolve negative customer
            feedback.
          </p>
        </div>
      </div>
    </div>
  );
};

export default GenReviewsHowUX;
